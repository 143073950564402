import React, { ReactElement } from 'react';
import {
  IntroWrapper,
  IntroImage,
  IntroTitle,
  Desciption,
  IntroInfo,
} from './style';

type PresentorProps = {
  name: string;
  about: string;
  link: string;
  image: ReactElement<string>;
};

const Presentor: React.FunctionComponent<PresentorProps> = ({
  name,
  about,
  image,
  link,
}) => {
  return (
    <IntroWrapper>
      <IntroImage>
        <a href={link} target="_blank">
          {image}
        </a>
      </IntroImage>
      <IntroInfo>
        <IntroTitle>
          <a href={link} target="_blank">
            <b>{name}</b>
          </a>
        </IntroTitle>
        <Desciption>{about}</Desciption>
      </IntroInfo>
    </IntroWrapper>
  );
};

export default Presentor;
