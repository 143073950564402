import * as React from 'react';
import { AboutWrapper, AboutPageTitle, AboutDetails } from './style';

interface AboutProps {}

const AboutContent: React.FunctionComponent<AboutProps> = () => {
  return (
    <AboutWrapper>
      <AboutPageTitle></AboutPageTitle>

      <AboutDetails>
        <h2>The Sawyer Seminar Series</h2>

        <p>
          “Black Reconstruction as a Portal” is a year-long seminar at UC Irvine
          during 2022-2023 that sets out to explore the global salience of
          visions of Black Reconstruction as a portal between the crisis that
          marks our current predicament and the freedom dreams of those who have
          taken to the streets insisting that another world is still possible.
          This seminar will explore W.E.B Du Bois’s historical study, Black
          Reconstruction, as a conduit to our present global crisis. If scholars
          and activists want to come to terms with the structural continuities
          in anti-Blackness today, perhaps there is no better place to start
          than to grapple with Du Bois’s study of the simultaneous
          possibilities—and ultimate foreclosure of—what he called abolition
          democracy at the very moment of slavery’s demise and the rise of Jim
          Crow. Situated within a historical record that positions the United
          States during a critical moment (buildup of the Civil War and the
          subsequent period of Reconstruction) within the context of competing
          and symbiotic global political, economic, and social systems, Black
          Reconstruction offers a model to understand complex structural and
          social issues.
        </p>
        <p>
          In our conversations, we seek to challenge the temporal delimitation
          of the relevance of Du Bois’s work to a prior historical era and its
          geographical confinement to the borders of the United States of
          America. Given that Du Bois’s broader scholarly career maintained an
          analytical focus on a necessarily global color line, we ask: What
          alternative visions of liberation might be unveiled if Black
          Reconstruction—both the text and the historical event—is deployed as a
          portal into our present global predicament? How do Du Bois’s
          theorizations of abolition democracy, education, general strike,
          capital, crisis, “the Coming of the Lord”, counter-revolution, land
          and labor, race and class, and the propaganda of history “travel” and
          speak to contemporary iterations of spiritual strivings, the feminist
          strike in Latin America, land occupations in Africa, and the rise of
          neo-fascism in Europe and North America?
        </p>
        <p>
          Damien Sojoyner, associate professor of anthropology, and Yousuf
          Al-Bulushi, assistant professor of global & international studies will
          co-direct the yearlong series, with support from postdoctoral fellow
          Marilyn Grell-Brisk, and PhD students Mariel Rowland and Temitope
          Famodu. The series is funded by the Sawyer Seminar grant from the
          Andrew W. Mellon Foundation. A Mellon Sawyer Seminar is akin to a
          temporary research center, in which faculty, postdoctoral, and
          graduate student participants from different disciplines engage in
          comparative research on the historical and cultural sources of
          contemporary developments.
        </p>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default AboutContent;
