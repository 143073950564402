import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/about';

const AboutPage = (props: any) => {
  const { data } = props;

  return (
    <Layout>
      <SEO title="About" description={data.site.siteMetadata.description} />
      <About />
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
