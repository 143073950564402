import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import AboutContent from './about';
import Presentor from './presentors';
import AboutWrapper from './style';

type PersonalBlogProps = {};

const About: React.FunctionComponent<PersonalBlogProps> = ({ ...props }) => {
  return (
    <AboutWrapper {...props}>
      <AboutContent />

      <Presentor
        image={
          <StaticImage
            src={'../../images/presentors/yousufAlBulushi.jpeg'}
            alt={'Yousuf Al-Bulushi'}
          />
        }
        name={'Yousuf Al-Bulushi'}
        about={
          'An Assistant Professor in the Department of Global & International Studies at UC Irvine and co-director of the Sawyer Seminar.'
        }
        link={'https://faculty.sites.uci.edu/yalbulushi/'}
      />
      <Presentor
        image={
          <StaticImage
            src={'../../images/presentors/marilynGrellBrisk.jpeg'}
            alt={'Marilyn Grell-Brisk'}
          />
        }
        name={'Marilyn Grell-Brisk'}
        about={
          'The Black Reconstruction as a Portal Mellon Sawyer Seminar Postdoctoral Scholar.'
        }
        link={'https://marilyngrell.com/'}
      />
      <Presentor
        image={
          <StaticImage
            src={'../../images/presentors/damienSojoyner.jpeg'}
            alt={'Damien Sojoyner'}
          />
        }
        name={'Damien Sojoyner'}
        about={
          'An Associate Professor in the Department of Anthropology at UC Irvine and co-director of the Sawyer Seminar.'
        }
        link={'http://faculty.sites.uci.edu/sojoyner/'}
      />
      <Presentor
        image={
          <StaticImage
            src={'../../images/presentors/marielRowland.jpeg'}
            alt={'Mariel Rowland'}
          />
        }
        name={'Mariel Rowland'}
        about={
          'A Graduate Student in the Program in Culture and Theory at UC Irvine.'
        }
        link={'https://www.humanities.uci.edu/students/mariel-rowland'}
      />

      <Presentor
        image={
          <StaticImage
            src={'../../images/presentors/temiFamodu.jpeg'}
            alt={'Temi Famodu'}
          />
        }
        name={'Temi Famodu'}
        about={
          'A graduate student in the department of Global Studies at UC Irvine.'
        }
        link={'#'}
      />
    </AboutWrapper>
  );
};

export default About;
